import React, { useContext, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  BENEFITS_PAGE,
  COMPLETE_BOOKING,
  THINGS_TO_DO_PAGE,
  PACKAGE_POST_PAGE,
  LISTINGPACKAGE_POSTS_PAGE,
  LISTING_INSURANCE_POSTS_PAGE,
  LISTING_THINGS_TO_DO_POSTS_PAGE,
  PURCHASES_PAGE,
  GROUPS_PAGE,
  DETAIL_GROUP_PAGE,
  CREATE_GROUP_PAGE,
  UPDATE_GROUP_PAGE,
  MONTHLYPAYMENT_PAGE,
  ACCUMULATION_PAGE
} from './settings/constant';
import Axios from 'axios';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_en from './languages/EN/global.json';
import global_es from './languages/ES/global.json';
import Loader from 'components/Loader/Loader';


i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: localStorage.getItem('language') !== null ? localStorage.getItem('language') : "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
})

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignIn" */ './container/Auth/SignIn/SignIn'
        ),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/SignUp/SignUp'
        ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/ForgetPassword'
        ),
      loading: Loading,
      modules: ['ForgetPassword'],
    }),
  },
  {
    path: `${SINGLE_POST_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: `${DETAIL_GROUP_PAGE}/:groupId`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/Groups/Detail/GroupDetail'
        ),
      loading: Loading,
      modules: ['DetailGroupView'],
    }),
  },
  {
    path: `${CREATE_GROUP_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/Groups/CreateEdit/CreateGroupForm'
        ),
      loading: Loading,
      modules: ['DetailGroupView'],
    }),
  },
  {
    path: `${UPDATE_GROUP_PAGE}`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/Groups/CreateEdit/EditGroup'
        ),
      loading: Loading,
      modules: ['DetailGroupView'],
    }),
  },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing'],
    }),
  },
  {
    path: `${LISTINGPACKAGE_POSTS_PAGE}`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/ListingPackage'),
      loading: Loading,
      modules: ['ListingPackage'],
    }),
  },
  {
    path: `${LISTING_INSURANCE_POSTS_PAGE}`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/ListingInsurance'),
      loading: Loading,
      modules: ['ListingInsurance'],
    }),
  },
  {
    path: `${LISTING_THINGS_TO_DO_POSTS_PAGE}`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/ThingsToDo/ListingThingsToDo'),
      loading: Loading,
      modules: ['ListingThingsToDo'],
    }),
  },
  {
    path: `${PACKAGE_POST_PAGE}/:package/:supplier`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Packages/Packages'),
      loading: Loading,
      modules: ['Packages'],
    }),
  },
  {
    path: `${THINGS_TO_DO_PAGE}`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/ThingsToDo/'),
      loading: Loading,
      modules: ['ThingsToDo'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
      loading: Loading,
      modules: ['Privacy'],
    }),
  },
  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
];

/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ './container/AddListing/AddListing'
    ),
  loading: Loading,
  modules: ['AddListing'],
});

const Benefits = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Benefits" */ './container/Benefits/IndexBenefits')
  ,
  loading: Loading,
  modules: ['IndexBenefits']
})

const CompleteBooking = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Benefits" */ './container/Booking/completeBooking')
  ,
  loading: Loading,
  modules: ['Booking']
})

const AgentDetailsViewPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
    ),
  loading: Loading,
  modules: ['AgentDetailsViewPage'],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

const PurchasesPage = Loadable({
  loader: () =>
    import("./container/Purchases/purchases"),
  loading: Loading,
  modules: ["PurchasesPage"]
})

const GroupsPage = Loadable({
  loader: () =>
    import("./container/Groups/Groups"),
  loading: Loading,
  modules: ["GroupsPage"]
})

const MonthlyPaymentPageId = {
  path: `${MONTHLYPAYMENT_PAGE}/:purchaseId`,
  component: Loadable({
    loader: () =>
      import("./container/Purchases/monthlyPayment"),
    loading: Loading,
    modules: ["MonthlyPaymentPage/:purchaseId"],
  })
}

const MonthlyPaymentPage = Loadable({
  loader: () =>
    import("./container/Purchases/monthlyPayment"),
  loading: Loading,
  modules: ["MonthlyPaymentPage"],
})

const AccumulationPage = Loadable({
  loader: () =>
    import("./container/Accumulation/accumulation"),
  loading: Loading,
  modules: ["Accumulation"]
})

const AccumulationPageId = {
  path: `${ACCUMULATION_PAGE}/:purchaseId`,
  component: Loadable({
    loader: () =>
      import("./container/Accumulation/accumulation"),
    loading: Loading,
    modules: ["Accumulation/:purchaseId"],
  })
}
/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const initialData = {
  "username": "a.gomez@visiontravel.net",
  "password": "4n4M4r14.321"
}

const Routes = () => {

  const { token, setToken, roomSelected, passengers, bookingRq } = useContext(AuthContext)

  useEffect(() => {
    const getToken = async () => {
      const result = await Axios.post(`${process.env.REACT_APP_API_URL}Token`, initialData);
      if (result.data.token) {
        localStorage.setItem('token', result.data.token);
        setToken({ token: result.data.token });
      }
    }
    getToken();
  }, [setToken])

  if (token === "")
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    )

  return (

    <I18nextProvider i18n={i18next}>
      <Layout>
        <Switch>
          {routes.map(({ path, component, exact = false }) => (
            <Route key={path} path={path} exact={exact} component={component} />
          ))}

          <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddListing} />

          <ProtectedRoute
            path={AGENT_ACCOUNT_SETTINGS_PAGE}
            component={AgentAccountSettingsPage}
          />

          <ProtectedRoute
            path={MonthlyPaymentPageId.path}
            component={MonthlyPaymentPageId.component}
          />

          <ProtectedRoute
            path={AccumulationPageId.path}
            component={AccumulationPageId.component}
          />

          <ProtectedRoute
            path={MONTHLYPAYMENT_PAGE}
            component={MonthlyPaymentPage}
          />

          <ProtectedRoute
            path={ACCUMULATION_PAGE}
            component={AccumulationPage}
          />

          <ProtectedRoute
            path={PURCHASES_PAGE}
            component={PurchasesPage}
          />

          <ProtectedRoute
            path={GROUPS_PAGE}
            component={GroupsPage}
          />

          <ProtectedRoute
            path={BENEFITS_PAGE}
            component={Benefits}
          />

          <ProtectedRoute
            path={AGENT_PROFILE_PAGE}
            component={AgentDetailsViewPage}
          />

          {((roomSelected.data && passengers.length > 0 && bookingRq.bookingDetail[0].productId === 1) ||
            (passengers.length > 0 && bookingRq.bookingDetail[0].productId === 4) ||
            bookingRq.bookingDetail[0].productId === 5 ||
            bookingRq.bookingDetail[0].productId === 10
          ) &&
            <ProtectedRoute
              path={COMPLETE_BOOKING}
              component={CompleteBooking}
            />
          }
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </I18nextProvider>
  );
};

export default Routes;