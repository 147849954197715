import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  // HOME_PAGE,
  // LISTING_POSTS_PAGE,
  PRIVACY_PAGE,
  // PRICING_PLAN_PAGE,
  // AGENT_PROFILE_PAGE,
} from 'settings/constant';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'library/hooks/useWindowSize';

const FooterMenu = () => {
  const { t } = useTranslation("global")
  const { width } = useWindowSize()
  return (
    <div
      style = {{
        display : "flex",
        flexDirection : "column",
        alignItems  : width < 768 ? "center" : "flex-start"
      }}
    >
      
      {/* <NavLink 
        to={`${PRIVACY_PAGE}`}
        style = {{
          color : "#868686"
        }}
      >
        Terms And conditions
      </NavLink>
    
      <NavLink 
        to={`${PRIVACY_PAGE}`}
        style = {{
          color : "#868686"
        }}
      >
        Terms And conditions
      </NavLink> */}
    
      <NavLink 
        to={`${PRIVACY_PAGE}`} 
        style = {{
          color : "#868686"
        }}
      >
        {t("footer.privacy")}
      </NavLink>
    
    </div>
  );
};

export default FooterMenu;
