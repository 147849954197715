import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { Menu } from 'antd';
import {iconEN, iconES} from '../../languages/icons/index';
import Logo from 'components/UI/Logo/Logo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AuthContext } from 'context/AuthProvider';

// styles

import NavbarWrapper, {
  Container,
  LogoArea,
  MenuArea,
  AvatarWrapper,
  AuthWrapper,
  MenuWrapper,
} from './Navbar.style';

const Navbar = ({
  className,
  logo,
  avatar,
  navMenu,
  authMenu,
  profileMenu,
  isLogin,
  headerType,
  searchComponent,
  location,
  searchVisibility,
}) => {
  const addAllClasses = ['navbar'];
  const history = useHistory();

  if (className) {
    addAllClasses.push(className);
  }
  if (headerType) {
    addAllClasses.push(`is_${headerType}`);
  }

  const [state, setState] = useState(false);
  const { i18n } = useTranslation("global")
  const { user } = useContext(AuthContext)

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  const handleDropdown = () => {
    setState(!state);
  };

  const changeLanguage = (value)=>{
    localStorage.removeItem('language');
    i18n.changeLanguage(value);
    localStorage.setItem('language', value)
    setState(false)
    history.go()
  }

  return (
    <NavbarWrapper className={addAllClasses.join(' ')}>
      <Container>
        {logo || searchVisibility ? (
          <LogoArea>
            {logo}
            {!searchVisibility && location.pathname === '/'
              ? null
              : searchComponent}
          </LogoArea>
        ) : null}
        <MenuArea>
          {navMenu && (
            <MenuWrapper className="main_menu">{navMenu}</MenuWrapper>
          )}
          <AvatarWrapper isLanguage = {true}>
            <div className="avatar-dropdown" ref={dropdownRef} >
              <div className="dropdown-handler" onClick={handleDropdown}>
                <Logo src = {i18n.language.toUpperCase() === "ES" ? iconES : iconEN}/>
              </div>

              <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`} style = {{display :"flex", flexDirection : "column", alignItems : "center"}}>
                <Menu.Item key = {0} style = {{padding : 0}}>
                  <button onClick = {()=> changeLanguage("es")}>
                    <Logo src = {iconES}/>
                  </button>
                </Menu.Item>
                <Menu.Item key = {1}>
                  <button onClick = {()=> changeLanguage("en")}>
                    <Logo src = {iconEN}/>
                  </button>
                </Menu.Item>
              </Menu>
            </div>
          </AvatarWrapper>
          {isLogin && avatar ? (
            <div
              style = {{
                display : "flex",
                alignItems : "center"
              }}
            >
              <div
                style = {{
                  display : "flex",
                  flexDirection : "column",
                  marginLeft : 20,
                  alignItems : "center"
                }}
              >
                <span
                  style = {{
                    fontSize : 16,
                    fontWeight : 600
                  }}
                >
                  { user.name }
                </span>
                <span
                  style = {{
                    fontSize : 14,
                    fontWeight : 600
                  }}
                >
                  { user.itaCode }
                </span>
              </div>
              <AvatarWrapper>{profileMenu}</AvatarWrapper>
            </div>
          ) : (
            authMenu && (
              <AuthWrapper className="auth_menu">{authMenu}</AuthWrapper>
            )
          )}
        </MenuArea>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  navMenu: PropTypes.element,
  avatar: PropTypes.element,
  authMenu: PropTypes.element,
  isLogin: PropTypes.bool,
  headerType: PropTypes.oneOf(['transparent', 'default']),
};

export default Navbar;
