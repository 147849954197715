// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';
export const BENEFITS_PAGE = '/benefits';
export const COMPLETE_BOOKING = '/completebooking';
export const BENEFITS_SUMMARY = '/summary';
export const BENEFITS_HISTORICAL= '/historical';
export const PURCHASES_PAGE = '/purchases'
export const MONTHLYPAYMENT_PAGE = '/purchases/monthlyPayment'
export const ACCUMULATION_PAGE = '/purchases/accumulation'
export const GROUPS_PAGE = '/groups';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const LISTINGPACKAGE_POSTS_PAGE = '/listingPackage';
export const LISTING_INSURANCE_POSTS_PAGE = '/listingInsurance';
export const LISTING_THINGS_TO_DO_POSTS_PAGE = '/listingThingsToDo'
export const SINGLE_POST_PAGE   = '/post';
export const THINGS_TO_DO_PAGE = '/thingsToDoDetail';
export const PACKAGE_POST_PAGE  = '/packageDetail';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';
export const AGENT_PROFILE_HISTORYBOOKING = '/historyBooking';
export const AGENT_PROFILE_REWARDPOINTS = '/rewardPoints';
export const AGENT_PROFILE_REGISTEREDCARDS = '/registeredCards';
export const AGENT_PROFILE_AUTHORIZEDS = '/authorizeds';
export const AGENT_PROFILE_DESTINATIONS = '/destinations';


// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';
export const DETAIL_GROUP_PAGE = '/detail-group';
export const UPDATE_GROUP_PAGE = '/update-group'
export const CREATE_GROUP_PAGE = '/create-group'

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';