import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
  BENEFITS_PAGE,
  // AGENT_ACCOUNT_SETTINGS_PAGE
  // PURCHASES_PAGE,
  // GROUPS_PAGE
} from 'settings/constant';
import { useTranslation } from 'react-i18next';



const MobileMenu = ({ className }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);
  const { t } = useTranslation("global")


  return (
    <Menu className={className}>

      {loggedIn && (
        <>
          <Menu.Item key="0">
            <NavLink exact to={`${BENEFITS_PAGE}`}>{t("header.Benefits")}</NavLink>
          </Menu.Item>
          {/*<Menu.Item key="4">
            <NavLink exact to={`${GROUPS_PAGE}`}>{t("header.groups")}</NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <NavLink exact to={`${PURCHASES_PAGE}`}>{t("header.purchases")}</NavLink>
      </Menu.Item>
          <Menu.Item key="1">
            <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>{t("userMenu.settings")}</NavLink>
      </Menu.Item>*/}
          <Menu.Item key="2">
            <Button onClick={logOut} style={{ marginLeft: 3 }}>{t("userMenu.logOut")}</Button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default MobileMenu;
