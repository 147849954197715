import React from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';
import { Col, Row } from 'antd';
import { FacebookIcon, TwitterIcon } from 'react-share';
import useWindowSize from 'library/hooks/useWindowSize';
import ARG from '../../assets/images/countries/ARG.png'
import CHL from '../../assets/images/countries/CHL.png'
import COL from '../../assets/images/countries/COL.png'
import DOM from '../../assets/images/countries/DOM.png'
import ECU from '../../assets/images/countries/ECU.png'
import MEX from '../../assets/images/countries/MEX.png'
import PAN from '../../assets/images/countries/PAN.png'
import PRI from '../../assets/images/countries/PRI.png'
import USA from '../../assets/images/countries/USA.png'
import VEN from '../../assets/images/countries/VEN.png'
import BALL from '../../assets/images/countries/ball.png'
import INSTAGRAM from '../../assets/images/instagram.png'
import YOUTUBE from '../../assets/images/youtube.png'
import { useTranslation } from 'react-i18next';


const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {

  const { width } = useWindowSize()
  const { t } = useTranslation("global")

  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        {logo && logo}
        <Row
          style={{
            marginLeft: 30,
            marginRight: 30
          }}
        >
          <Col xs={24} md={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 10
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 200,
                display: "flex",
                flexDirection: "column",
                alignItems: width > 768 ? "flex-start" : "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "Rubik",
                  marginBottom: 10,

                }}
              >
                {t("footer.otherLinks")}
              </span>
              {menu && <MenuWrapper>{menu}</MenuWrapper>}
            </div>
          </Col>

          <Col xs={24} md={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 10,
            }}
          >
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: "Rubik",
                marginBottom: 10
              }}
            >
              {t("footer.internationalSites")}
            </span>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
              }}
              gutter={[5, 5]}
            >
              <Col>
                <img
                  src={ARG}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={CHL}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={COL}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={DOM}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={ECU}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={MEX}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={PAN}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={PRI}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={USA}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
              <Col>
                <img
                  src={VEN}
                  alt=""
                  style={{
                    width: 30,
                    height: 20
                  }}
                />
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                marginTop: 10
              }}
            >
              {t("footer.powerdby")}
              <img
                src={BALL}
                alt=""
                style={{
                  width: 30,
                  height: 20,
                  marginLeft: 10
                }}
              />
              <span
                style={{
                  color: "#868686",
                  fontSize: 15
                }}
              >
                Visión Travel
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10
              }}
            >
              <FacebookIcon
                size={32}
                round={true}
                style={{
                  marginRight: 5, cursor: "pointer"
                }}
                onClick={() => window.open("https://www.facebook.com/visiontravelbyvisionbiz", "_blank")}
              />
              <TwitterIcon size={32} round={true} style={{ marginRight: 5, cursor: "pointer" }} />
              <img
                src={INSTAGRAM}
                alt=""
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  marginRight: 5,
                  cursor: "pointer"
                }}
                onClick={() => window.open("https://www.instagram.com/visiontravel.vb/", "_blank")}
              />
              <img
                src={YOUTUBE}
                alt=""
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  marginRight: 5,
                  cursor: "pointer"
                }}
                onClick={() => window.open("https://www.youtube.com/user/visiontravelinc", "_blank")}
              />

            </div>
          </Col>

          <Col xs={24} md={8}
            style={{
              padding: 10,
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: "Rubik"
              }}
            >
              {t("footer.legalInformation")}
            </span>
            <p
              style={{
                width: width > 768 ? "100%" : "100%",
                color: "#868686",
                fontSize: 13,
                marginTop: 10
              }}
            >
              Global Vision Network LLC, DBA Vision Travel, IATAN/ARC 10-6 3956 5 – Florida Seller of Travel ST36695, CLIA #00612220 - We do not sell to California Customers
            </p>
          </Col>
        </Row>
        {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
