import React, { useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import MapAutoComplete from 'components/Map/MapAutoComplete';
import { AuthContext } from 'context/AuthProvider';

// styles
import { NavbarSearchWrapper } from './Header.style';


const NavbarSearch = (props) => {
  const { setHotelData }  = useContext(AuthContext)
  const history = useHistory();

  
  const search = () => {
    setHotelData(undefined)
    if(history.location.pathname !== '/listing'){
      history.push('/listing')
    }
  }

  return (
    <NavbarSearchWrapper className="navbar_search">
      <div
        style = {{
          width : "100%"
        }}
      >
        <MapAutoComplete />
      </div>
      <div
        style = {{
          marginLeft : 10
        }}
      >
        <FiSearch 
          style = {{
            cursor : "pointer"
          }}
          onClick = { ()=> search() }
          size = {20}
        /> 
      </div>
    </NavbarSearchWrapper>
  );
};

export default withRouter(NavbarSearch);
