import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import { LOGIN_PAGE } from 'settings/constant';
import { useTranslation } from 'react-i18next';

const AuthMenu = ({ className }) => {
  const { t } = useTranslation("global")
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink to={LOGIN_PAGE} style = {{padding :5}}>{t("header.signInButton")}</NavLink>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <NavLink to={REGISTRATION_PAGE}>Sign up</NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

export default AuthMenu;
