import React, { useState, useContext, useEffect } from 'react';
import { AutoComplete } from 'antd';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/AuthProvider';
import { LoadingOutlined } from '@ant-design/icons';

const SearchInput = () => {
  
  
  const {token, hotelSearch, setHotelSearch } = useContext(AuthContext);
  const [data, setData] = useState(localStorage.getItem('citySearchForm') ? JSON.parse(localStorage.getItem('citySearchForm')) : [] )
  const city = (hotelSearch.destinationId !=="" && data.length > 0) ? data.filter(x=> x.id === hotelSearch.destinationId) : "";
  const [value, setValue] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  const { t, i18n } = useTranslation("global");
  
  const onChange = (value)=>{
    setValue(value);
    if(value !== "" && value.length>= 3 ){
      getCities(value)
    }
    else{
      setData([])
      setHotelSearch({...hotelSearch, destinationId : ""});
    }
  }

  const onSelect = async (dataSelect)=>{
    const result = await data.filter(x=> x.value === dataSelect);
    setHotelSearch({...hotelSearch, destinationId : result[0].id})
    localStorage.setItem('citySearchForm', JSON.stringify(data));
  }
  
  const getCities = async(value)=>{
    setIsLoading(true)
    const result = await Axios.get(`${process.env.REACT_APP_API_URL}City/GetByText/${i18n.language.toUpperCase()}/${value}`, {
      headers : {
        "Authorization" : `Bearer ${token.token}`
      }
    });
    if(result.data){
      const values = await result.data.map(x=> {
        return {
          id    : x.id,
          value : x.name
        }
      });
      setData(values);
    }
    else{
      setData([])
    }
    setIsLoading(false)
  }

  
  useEffect(()=>{
      if(city.length > 0)
      setValue(city.length > 0 ? city[0].value : "")
  },[city])
  

  return (
    <div style = {{display : "flex", justifyContent  : "center", width : "100%", alignItems : "center"}}>
      <AutoComplete
        value={value}
        options={data}
        size = "large"
        style={{minWidth : "200px", width: "100%"}}
        bordered = {false}
        onSelect={onSelect}
        onChange={onChange}
        placeholder={t("listing.filters.minCharacters")}
      />
     { isLoading &&
        <div
          style = {{
            width : 40,
            height : 40, 
            display : "flex", 
            justifyContent  : "center", 
            alignItems : "center"
          }}
        >
          <LoadingOutlined spin size = {40}/>
        </div>
}
    </div>
  );
}

const MapAutoComplete = (props) => {
  const { updatevalue } = props;
  return <SearchInput getinputvalue={updatevalue} />;
};

export default MapAutoComplete;
