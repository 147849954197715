import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';

// style
import LogoArea from './Logo.style';


const Logo = ({ className, withLink, linkTo, title, src }) => {

  const { user } = useContext(AuthContext);
  const onErrorImage = ( e )  => {
    e.target.onerror = null;
    e.target.src = `https://cdn1.visiontravel.net/Images/avatar/default_${user.gender}.jpg`
  }

  return (
    <LogoArea className={className}>
      {withLink ? (
        <NavLink to={linkTo}>
          {src && <img src={src} alt="Vívelo" style = {{width : 40, height : 40, color: '#30DF15', objectFit : "cover"}} onError = { onErrorImage }/>}
          {title && <h3>{title}</h3>}
        </NavLink>
      ) : (
        <Fragment>
          {src && <img src={src} alt="Vívelo" style = {{width : 40, height : 40, color: '#30DF15', objectFit : "cover"}} onError = { onErrorImage }/>}
          {title && <h3>{title}</h3>}
        </Fragment>
      )}
    </LogoArea>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
};

export default Logo;
