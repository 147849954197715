import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import AuthProvider from 'context/AuthProvider';
import Routes from './router';
import 'antd/dist/antd.css';

//styles
import GlobalStyles from 'assets/style/Global.style';


const App = () => {
  return(
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <BrowserRouter>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </BrowserRouter>
      </>
    </ThemeProvider>
  )
};

ReactDOM.render(<App />, document.getElementById('root'));
