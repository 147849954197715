import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  BENEFITS_PAGE
  // , PURCHASES_PAGE, GROUPS_PAGE
} from 'settings/constant';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/AuthProvider';

const MainMenu = ({ className }) => {

  const [t] = useTranslation("global")
  const { user } = useContext(AuthContext)

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Menu className={className}>
        {user.active &&
          <>
            <Menu.Item key="0">
              <NavLink exact to={`${BENEFITS_PAGE}`}>
                {t("header.Benefits")}
              </NavLink>
            </Menu.Item>
            {/*<Menu.Item key="2">
                <NavLink exact to={`${GROUPS_PAGE}`}>
                  {t("header.groups")}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="1">
                <NavLink exact to={`${PURCHASES_PAGE}`}>
                  {t("header.purchases")}
                </NavLink>
              </Menu.Item>*/}
          </>
        }
      </Menu>
    </div>
  );
};

export default MainMenu;
